import React from 'react'
import PropTypes from 'prop-types'
import { MemoryRouter } from 'react-router-dom'

import httpClient from 'tools/httpClient'
import { CLOSE_WIDGET_EVENT } from 'constants/postMessageEvents'
import WidgetComponent from './component'

class Widget extends React.Component {
  state = {
    isLoading: true,
    appId: null,
    workspaceId: null,
  }

  async componentDidMount() {
    const {
      match: {
        params: { slug, widgetId: appId, workspaceId },
      },
    } = this.props

    if (slug) {
      await this.fetchWidgetBySlug()
    } else {
      this.setState({ appId, workspaceId, isLoading: false })
    }
  }

  get closable() {
    const {
      location: { search },
    } = this.props

    const query = new URLSearchParams(search)
    return query.get('closable') === 'true'
  }

  get additionalProps() {
    if (this.closable) {
      return { handleCloseWidget: this.handleCloseWidget }
    }

    return {}
  }

  fetchWidgetBySlug = async () => {
    const {
      match: {
        params: { slug, workspaceCode },
      },
    } = this.props

    try {
      const {
        data: { data },
      } = await httpClient.get(`/workspaces/${workspaceCode}/public/widgets/custom_pages/${slug}`)

      this.setState({ appId: data.id, workspaceId: workspaceCode, isLoading: false })
    } catch {
      this.setState({ isLoading: false })
    }
  }

  handleCloseWidget = () => {
    window.parent.postMessage(CLOSE_WIDGET_EVENT, '*')
  }

  render = () => (
    <MemoryRouter>
      <WidgetComponent {...this.state} {...this.additionalProps} />
    </MemoryRouter>
  )
}

Widget.propTypes = {
  match: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
}

export default Widget
