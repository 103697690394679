import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import { SENTRY_DSN, SENTRY_ENV, IS_SENTRY_DISABLED } from '../constants'

export default () => {
  if (!IS_SENTRY_DISABLED) {
    Sentry.init({
      dsn: SENTRY_DSN,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 0.0,
      environment: SENTRY_ENV,
    })
  }
}
