import React from 'react'
import PropTypes from 'prop-types'
import { useRouteMatch } from 'react-router-dom'
import { path } from 'ramda'

import IntlProvider from 'views/IntlProvider'

const Localization = ({ children }) => {
  const matchEmbedded = useRouteMatch('/embeded/:workspaceCode/:widgetId')
  const matchSlugged = useRouteMatch('/:workspaceCode/:slug')
  const workspaceCode = path(['params', 'workspaceCode'], matchEmbedded || matchSlugged)

  return <IntlProvider workspaceCode={workspaceCode}>{children}</IntlProvider>
}

Localization.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Localization
