import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import configureStore from 'state/store'
import Error from './Error'
import Widget from './Widget'
import GlobalStyles from './GlobalStyles'
import LocalizationProvider from './LocalizationProvider'

const store = configureStore()

const App = () => (
  <Provider store={store}>
    <GlobalStyles />
    <BrowserRouter>
      <LocalizationProvider>
        <Switch>
          <Route path="/embeded/:workspaceId/:widgetId" component={Widget} />
          <Route path="/:workspaceCode/:slug" component={Widget} />
          <Route path="/" component={Error} />
        </Switch>
      </LocalizationProvider>
    </BrowserRouter>
  </Provider>
)

export default App
