import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Loader from 'views/shared/Loader'
import Widget from 'views/Widget'
import Error from '../Error'

const WidgetComponent = ({ appId, workspaceId, isLoading, handleCloseWidget }) => {
  if (isLoading) {
    return (
      <WidgetComponent.LoadingWrapper>
        <Loader size={72} />
      </WidgetComponent.LoadingWrapper>
    )
  }

  return appId && workspaceId ? (
    <Widget appId={appId} workspaceId={workspaceId} handleCloseWidget={handleCloseWidget} />
  ) : (
    <Error />
  )
}

WidgetComponent.defaultProps = {
  appId: null,
  workspaceId: null,
  handleCloseWidget: undefined,
}

WidgetComponent.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  appId: PropTypes.string,
  workspaceId: PropTypes.string,
  handleCloseWidget: PropTypes.func,
}

WidgetComponent.LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

export default WidgetComponent
