// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-block {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.error-block__wrap {
  width: 100%;
}
.error-block__wrap--full {
  width: 100vw !important;
  flex-grow: 1;
  height: inherit;
  display: flex;
  flex-direction: column;
}
.error-block__wrap--full .error-block__inner {
  border-right: none;
  border-left: none;
}
.error-block__wrap--full .error-block__footer {
  padding-top: 16px;
  padding-bottom: 16px;
}
.error-block__footer {
  color: #525f7f;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
}

@media (min-width: 1200px) {
  .error-block__wrap {
    width: 1056px;
  }
}
`, "",{"version":3,"sources":["webpack://./apps/widget_page/src/App/Error/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;AACA;EACE,WAAW;AACb;AACA;EACE,uBAAuB;EACvB,YAAY;EACZ,eAAe;EACf,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,kBAAkB;EAClB,iBAAiB;AACnB;AACA;EACE,iBAAiB;EACjB,oBAAoB;AACtB;AACA;EACE,cAAc;EACd,eAAe;EACf,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".error-block {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n}\n.error-block__wrap {\n  width: 100%;\n}\n.error-block__wrap--full {\n  width: 100vw !important;\n  flex-grow: 1;\n  height: inherit;\n  display: flex;\n  flex-direction: column;\n}\n.error-block__wrap--full .error-block__inner {\n  border-right: none;\n  border-left: none;\n}\n.error-block__wrap--full .error-block__footer {\n  padding-top: 16px;\n  padding-bottom: 16px;\n}\n.error-block__footer {\n  color: #525f7f;\n  font-size: 12px;\n  line-height: 16px;\n  text-align: center;\n}\n\n@media (min-width: 1200px) {\n  .error-block__wrap {\n    width: 1056px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
