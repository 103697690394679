import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: url('${ASSET_CDN}/assets/fonts/inter/inter-v13-latin-regular.woff2') format('woff2'),
    url('${ASSET_CDN}/assets/fonts/inter/inter-v13-latin-regular.woff') format('woff');
    font-display: swap;
  }
  html {
    height: 100%;
  }
  body {
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: white;
    font-family: 'Inter', sans-serif;
    webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  #root {
    height: 100%;
  }
  p {
    margin: 0;
  }
  a {
    text-decoration: none;
    color: #2cbc64;
  }
  a:focus {
    outline: 0;
  }
  img {
    width: 100%;
    height: 100%;
  }
`

export default GlobalStyles
