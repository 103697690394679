import React from 'react'
import { FormattedMessage } from 'react-intl'

import ErrorBody from 'views/shared/Error'
import { EXPERT_BOX_LINK } from 'constants'

import './style.css'

const Error = () => (
  <div className="error-block">
    <div className="error-block__wrap error-block__wrap--full">
      <ErrorBody />
      <p className="error-block__footer">
        <FormattedMessage
          id="widgetPage.footer.agreement"
          values={{
            expertBoxUrl: (
              <a href={EXPERT_BOX_LINK} rel="noopener noreferrer" target="_blank">
                <FormattedMessage id="widgetPage.footer.agreementLink" />
              </a>
            ),
          }}
        />
      </p>
    </div>
  </div>
)

export default Error
